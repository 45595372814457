<span fxLayout="column" class="mat-app-background-1">
  <span *ngIf="searchState == eSearchState.Inactive" fxLayout="row" class="mat-app-background-1" fxFill
    fxLayoutAlign="center center">
    <span fxLayout="column" fxFlex="0 1 900px" fxFlex.sm="100" fxFlex.xs="100" fxFlexFill fxLayoutGap="20px"
      class="position-relative">
      <app-hero [isHandset]="isHandset$ | async" [isWeb]="!(isXs$ | async)"
        [slides]="(fpConfigStore.fpConfig$ | async)?.hero"></app-hero>
    </span>
  </span>

  <span fxLayout="row" class="mat-app-background-1" fxLayoutAlign="center start">
    <span fxLayout="column" fxFlex="0 1 900px" fxFlex.sm="100" fxFlex.xs="100" fxFlexFill fxLayoutGap="20px"
      fxLayoutAlign="center center">
      <app-search-bar aria-label="Suchen" class="mat-background-primary" [searchError]="store.searchError$ | async">
      </app-search-bar>
    </span>
  </span>
  <span *ngIf="searchState !== eSearchState.Inactive">
    <span fxLayout="row" class="mat-app-background-1 pt-3 pb-3" fxLayoutAlign="center start">
      <span fxLayout="column" fxFlex="0 1 900px" fxFlex.sm="100" fxFlex.xs="100" fxFlexFill fxLayoutGap="20px"
        fxLayoutAlign="center center">
        <app-search-header></app-search-header>
      </span>
    </span>
  </span>
  <!-- Search has results or is active-> show result list -->
  <span
    *ngIf="(searchState === eSearchState.HasResults || searchState === eSearchState.Searching) && (this.store.searchResults$ | async)">
    <span fxLayout="row" class="mat-app-background-1 pt-3 pb-3" fxLayoutAlign="center start">
      <span fxLayout="column" fxFlex="0 1 900px" fxFlex.sm="100" fxFlex.xs="100" fxFlexFill fxLayoutGap="20px"
        fxLayoutAlign="center center">
        <app-results [results]="(this.store.searchResults$ | async)" [isHandset]="isHandset$ | async"
          [pageTopOffset]="store.resultCurrentIndex$ | async" aria-label="Suchergebnis" fxLayout="column">
        </app-results>
      </span>
    </span>
  </span>

  <!-- Search has no results -> show message -->
  <span *ngIf="!redYellowSearch && searchState == eSearchState.HasNoResults">
    <span fxLayout="row" class="mat-app-background-1 pt-3 pb-3" fxLayoutAlign="center start">
      <span fxLayout="column" fxFlex="0 1 900px" fxFlex.sm="100" fxFlex.xs="100" fxFlexFill fxLayoutGap="20px"
        fxLayoutAlign="center center">
        {{'searchList.notFound' | translate}}
      </span>
    </span>
  </span>

  <span *ngIf="redYellowSearch && searchState == eSearchState.HasNoResults">
    <span fxLayout="row" class="mat-app-background-1 pt-3 pb-3" fxLayoutAlign="center start">
      <span fxLayout="column" fxFlex="0 1 900px" fxFlex.sm="100" [ngClass]="{ 'px20px': (isHandset$ | async)}"
        fxFlex.xs="100" fxFlexFill fxLayoutGap="20px" fxLayoutAlign="center center">
        <span>{{'searchList.notFound' | translate}}</span>
        <span>{{'searchList.redYellowCase' | translate}}</span>
      </span>
    </span>
  </span>

  <!-- Search is not active -> show swimmlanes and co -->

  <!-- NEW Frontpage aka Frontpage2-->

  <span fxLayout="column" *ngIf="searchState == eSearchState.Inactive">
    <ng-container *ngFor="let row of (fpConfigStore.fpConfig$ | async)?.rows; let idx = index">
      <ng-container [ngSwitch]="row.rowType">
        <span [ngStyle]="{'padding':'0px', 
          'padding-top': ((idx > 0) && ((fpConfigStore.fpConfig$ | async)?.rows[idx-1].grey == row.grey)) ? '20px' : '23px', 
          'padding-bottom': ((idx < (fpConfigStore.fpConfig$ | async)?.rows.length -1) && ((fpConfigStore.fpConfig$ | async)?.rows[idx+1].grey == row.grey)) ? '20px' : '23px'}
          
          "
          [ngClass]="{'mat-app-background-2': row.grey === true, 'mat-app-background-1' : row.grey == null || row.grey === false }">

          <ng-container *ngSwitchCase="'swimlane'">
            <span fxLayout="row" fxLayoutAlign="center start">
              <span fxLayout="column" fxFlex="100" fxLayoutGap="36px">
                <swimlanes [swimlane]="row.data" [tvLane]="row.data.swimlaneclass === 'tv'"
                  [bigCards]="row.data.size ==='l'" *ngIf="row.data.items.length > 0">
                </swimlanes>
              </span>
            </span>
          </ng-container>

          <ng-container *ngSwitchCase="'tvchannellane'">
            <app-channels [title]="'searchList.searchLinks.tv' | translate" [isHandset]="isHandset$ | async"
              [channels]="tvChannels">
            </app-channels>
          </ng-container>

          <ng-container *ngSwitchCase="'streamerlane'">
            <app-channels [title]="'searchList.searchLinks.stream' | translate" [isHandset]="isHandset$ | async"
              [channels]="streamChannels">
            </app-channels>
          </ng-container>

          <ng-container *ngSwitchCase="'redtexttops'">
            <span fxLayout="row" fxLayoutAlign="center start">

              <span fxLayout="column" fxFlex="0 1 900px" fxFlex.sm="100" fxFlex.xs="100" fxFill
                fxLayoutAlign="center start" [ngClass]="{'px20px': (isHandset$ | async)}">

                <span fxLayout="row" fxLayoutAlign="space-between center" class="carousel-fix-width w-100">
                  <span class="mat-h1 primary-color mb10px"> {{'searchList.chosenTopicsTitle' | translate}}</span>
                  <a class="f-hint-link text-nowrap text-decoration-none black mb10px icon-position" fxLayout="row"
                    fxLayoutAlign="center center" [routerLink]="['/ratgeber']">
                    <span>{{'swimlane.toRatgeber' | translate | uppercase}}</span>
                    <mat-icon color="primary" class="fs22">keyboard_arrow_right</mat-icon>
                  </a>
                </span>
                <span class="mb1">{{'searchList.chosenTopicsText' | translate}}</span>
                <span fxLayout="row wrap" fxLayoutGap="10px" fxFlexOffset="14px">
                  <mat-basic-chip [disableRipple]="true" class="f-chip chip outline-none clickable"
                    *ngFor="let r of row.data.buttons">
                    <a class="text-decoration-none black" [routerLink]="r.uri">{{r.label}}</a>
                  </mat-basic-chip>
                </span>
              </span>
            </span>
          </ng-container>

          <ng-container *ngSwitchCase="'special'">
            <app-special *ngIf="row.data" fxLayout="row" fxLayoutAlign="center start" [isHandset]="isHandset$ | async"
              [isXs]="isXs$ | async" [special]="row.data">
            </app-special>
          </ng-container>


          <ng-container *ngSwitchCase="'feature'">
            <app-feature *ngIf="row.data" fxLayout="row" fxLayoutAlign="center start" [isHandset]="isHandset$ | async"
              [isXs]="isXs$ | async" [feature]="row.data">
            </app-feature>
          </ng-container>
        </span>
      </ng-container>
    </ng-container>
  </span>

  <span fxFlex="50px"></span>

  <span fxLayout="row" class="mat-app-background-4 footer-text-color pt41px pb40px" fxLayoutAlign="center start"
    [ngClass]="{ 'px20px': (isHandset$ | async)}">
    <span fxLayout="column" fxFlex="0 1 900px" fxFlex.sm="100" fxFlex.xs="100" fxFill fxLayoutAlign="center start">

      <span class="mat-h3" fxLayout="row">{{'static.members.title' | translate}}</span>
      <span fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="15px">
        <a href="https://www.blm.de" target="_blank" class="text-decoration-none black"><img height="90" width="144"
            alt="BLM" title="Bayerische Landeszentrale für neue Medien (BLM)" src="/assets/media/members/blm.svg"
            class="border-radius-4px mb" loading="lazy" fetchprioritiy="low"></a>
        <a href="https://www.lfk.de" target="_blank" class="text-decoration-none black"><img height="90" width="144"
            alt="LFK" title="Landesanstalt für Kommunikation Baden-Württemberg (LFK)"
            src="/assets/media/members/lfk.svg" class="border-radius-4px mb" loading="lazy" fetchprioritiy="low"></a>
        <a href="https://www.lpr-hessen.de" target="_blank" class="text-decoration-none black"><img height="90"
            width="144" alt="LPR Hessen"
            title="Hessische Landesanstalt für privaten Rundfunk und neue Medien (LPR Hessen)"
            src="/assets/media/members/lpr.svg" class="border-radius-4px mb"></a>
        <!-- <a href="https://medienanstalt-mv.de" target="_blank" class="text-decoration-none black"><img height="90"
            width="144" alt="mmv" title="Medienanstalt Mecklenburg-Vorpommern (MMV)" src="/assets/media/members/mmv.svg"
            class="border-radius-4px mb" loading="lazy" fetchprioritiy="low"></a> -->
        <a href="https://www.nlm.de" target="_blank" class="text-decoration-none black"><img height="90" width="144"
            alt="NLM" title="Niedersächsische Landesmedienanstalt (NLM)" src="/assets/media/members/nlm.svg"
            class="border-radius-4px mb" loading="lazy" fetchprioritiy="low"></a>
        <a href="https://www.medienanstalt-nrw.de" target="_blank" class="text-decoration-none black"><img height="90"
            width="144" alt="Landesanstalt NRW" title="Landesanstalt für Medien NRW" src="/assets/media/members/nrw.svg"
            class="border-radius-4px mb" loading="lazy" fetchprioritiy="low"></a>
        <a href="https://medienanstalt-rlp.de" target="_blank" class="text-decoration-none black"><img height="90"
            width="144" alt="Medienanstalt RLP" title="Medienanstalt Rheinland-Pfalz"
            src="/assets/media/members/rlp.svg" class="border-radius-4px mb" loading="lazy" fetchprioritiy="low"></a>
        <a href="https://www.slm-online.de" target="_blank" class="text-decoration-none black"><img height="90"
            width="144" alt="SLM" title="Sächsische Landesanstalt für privaten Rundfunk und neue Medien (SLM)"
            src="/assets/media/members/slm.svg" class="border-radius-4px mb" loading="lazy" fetchprioritiy="low"></a>
        <a href="https://medienanstalt-sachsen-anhalt.de" target="_blank" class="text-decoration-none black"><img
            height="90" width="144" alt="MSA" title="Medienanstalt Sachsen-Anhalt (MSA)"
            src="/assets/media/members/msa.svg" class="border-radius-4px mb" loading="lazy" fetchprioritiy="low"></a>
        <a href="https://www.tlm.de" target="_blank" class="text-decoration-none black"><img height="90" width="144"
            alt="TLM" title="Thüringer Landesmedienanstalt (TLM)" src="/assets/media/members/tlm.svg"
            class="border-radius-4px mb" loading="lazy" fetchprioritiy="low"></a>
        <a href="https://www.stiftung-medienpaedagogik-bayern.de" target="_blank"
          class="text-decoration-none black"><img height="90" width="144" alt="Stiftung"
            title="Stiftung Medienpädagogik Bayern" src="/assets/media/members/blmstiftung.svg"
            class="border-radius-4px mb" loading="lazy" fetchprioritiy="low"></a>
        <a href="http://www.br-online.de/jugend/izi" target="_blank" class="text-decoration-none black"><img height="90"
            width="144" alt="IZI" title="Internationales Zentralinstitut für das Jugend- und Bildungsfernsehen (IZI)"
            src="/assets/media/members/izi.svg" class="border-radius-4px mb" loading="lazy" fetchprioritiy="low"></a>
      </span>

      <span fxFlex="50px"></span>

      <span class="mat-h4" fxLayout="row">{{'static.members.subtitle' | translate}}</span>
      <span fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="15px">
        <a href="https://www.bremische-landesmedienanstalt.de" target="_blank" class="text-decoration-none black"><img
            height="90" width="144" alt="brema" title="Bremische Landesmedienanstalt (brema)"
            src="/assets/media/members/brema.png" class="border-radius-4px mb" loading="lazy" fetchprioritiy="low"></a>
        <!-- <a href="https://www.ma-hsh.de" target="_blank" class="text-decoration-none black"><img height="90" width="144"
            alt="MA SHS" title="Medienanstalt Hamburg/Schleswig-Holstein (MA HSH)" src="/assets/media/members/mahsh.svg"
            class="border-radius-4px mb" loading="lazy" fetchprioritiy="low"></a> -->
        <a href="https://www.ostbelgienlive.be" target="_blank" class="text-decoration-none black"><img height="90"
            width="144" alt="OSB" title="Ostbelgien mit Unterstützung der Deutschsprachigen Gemeinschaft Belgiens"
            src="/assets/media/members/osb.svg" class="border-radius-4px mb" loading="lazy" fetchprioritiy="low"></a>
      </span>

      <span fxFlex="20px"></span>
    </span>


  </span>